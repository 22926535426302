export const EMAIL_REGEXP =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:%\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const SUBMITTED = "SUBMITTED";
export const CONST_EMAIL_MAX_SYMBOLS = 255;
export const MAX_PASSWORD_LENGTH = 255;
export const MIN_PASSWORD_LENGTH = 5;

export const TOKEN_KEY = "auth_key";

export const errorEventsName = {
  empty: "err_lnd_sign_up_name_noname",
  too_short: "err_lnd_sign_up_name_shortname",
  invalid_chars: "err_lnd_sign_up_name_symbolname",
};

export const errorEventsBirthday = {
  M: "err_lnd_sign_up_age_onlymonth",
  D: "err_lnd_sign_up_age_onlyday",
  Y: "err_lnd_sign_up_age_onlyyear",
  MD: "err_lnd_sign_up_age_onlydaymonth",
  DY: "err_lnd_sign_up_age_onlydayyear",
  MY: "err_lnd_sign_up_age_onlymonthyear",
};

export const getErrorEventEmail = (errorText, type) => {
  const errorEvents = {
    "Field cannot be empty.": `err_lnd_${type}_email_noemail`,
    "Your email address is not valid. Please, check it.": `err_lnd_${type}_email_wrongemail`,
    "This email address has already been used.": `err_lnd_${type}_email_againemail`,
  };

  return errorEvents[errorText];
};

export const getErrorEventPassword = (errorType, type) => {
  const errorEvents = {
    empty: `err_lnd_${type}_password_nopassword`,
    too_short: `err_lnd_${type}_password_shortpassword`,
    empty_repeat: `err_lnd_${type}_password2_nopassword`,
    not_matches: `err_lnd_${type}_password2_wrongpassword`,
  };

  return errorEvents[errorType];
};
